import React from 'react';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import Sidebar from '../Sidebar';
import RightHandBar from '../RightHandBar';
import GlobalStyles from '../../styles/global';
import { WithChildren } from '../../models';

import * as S from './style';

const Layout = ({ children }: WithChildren) => {
  return (
    <S.LayoutWrap>
      <GlobalStyles />
      <TransitionPortal level="top">
        <Sidebar />
      </TransitionPortal>
      <S.LayoutMain>{children}</S.LayoutMain>
      <TransitionPortal level="top">
        <RightHandBar />
      </TransitionPortal>
    </S.LayoutWrap>
  );
};

export default Layout;
