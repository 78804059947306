import React, { useState, useEffect } from 'react';
import { Home } from '@styled-icons/boxicons-solid/Home';
import { SearchAlt2 as Search } from '@styled-icons/boxicons-regular/SearchAlt2';
import { UpArrowAlt as Arrow } from '@styled-icons/boxicons-regular/UpArrowAlt';
import { Moon } from '@styled-icons/boxicons-regular/Moon';
import { Sun } from '@styled-icons/boxicons-regular/Sun';
import { Grid } from '@styled-icons/boxicons-solid/Grid';
import { ThList as List } from '@styled-icons/typicons/ThList';

import * as S from './style';
import getThemeColor from '../../utils/getThemeColor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const RightHandBar = () => {
  const [theme, setTheme] = useState(null);
  const [display, setDisplay] = useState(null);

  const isDarkMode = theme === 'night';
  const isListMode = display === 'list';

  useEffect(() => {
    setTheme(window.__theme);
    setDisplay(window.__display);
    window.__onThemeChange = () => setTheme(window.__theme);
    window.__onDisplayChange = () => setDisplay(window.__display);
  }, []);

  return (
    <S.MenuBarWrapper>
      <S.MenuBarGroup>
        <S.MenuBarLink
          cover
          direction="left"
          bg={getThemeColor()}
          duration={0.6}
          to="/"
          title="Back to Home"
        >
          <S.MenuBarItem>
            <Home />
          </S.MenuBarItem>
        </S.MenuBarLink>
        <S.MenuBarLink
          cover
          direction="left"
          bg={getThemeColor()}
          duration={0.6}
          to="/search/"
          title="Search"
        >
          <S.MenuBarItem>
            <Search />
          </S.MenuBarItem>
        </S.MenuBarLink>
      </S.MenuBarGroup>
      <S.MenuBarMobileGroup>
        <S.MenuBarLink
          cover
          direction="top"
          bg={getThemeColor()}
          duration={0.6}
          to="/about/"
        >
          <S.MenuMobileItem>About Me</S.MenuMobileItem>
        </S.MenuBarLink>
        <S.MenuBarLink
          cover
          direction="top"
          bg={getThemeColor()}
          duration={0.6}
          to="/portfolio/"
        >
          <S.MenuMobileItem>Portfolio</S.MenuMobileItem>
        </S.MenuBarLink>
      </S.MenuBarMobileGroup>
      <S.MenuBarGroup>
        <S.MenuBarItem
          title={`Switch to ${isDarkMode ? 'Day' : 'Night'} theme`}
          onClick={() => {
            window.__setPreferredTheme(isDarkMode ? 'day' : 'night');
          }}
          className={theme}
        >
          {isDarkMode ? <Sun /> : <Moon />}
        </S.MenuBarItem>
        <S.MenuBarItem
          title={`Switch to ${isListMode ? 'Grid' : 'List'} layout`}
          onClick={() => {
            window.__setPreferredDisplay(isListMode ? 'grid' : 'list');
          }}
          className="display"
        >
          {isListMode ? <Grid /> : <List />}
        </S.MenuBarItem>
        <S.MenuBarItem
          title="Back to top"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <Arrow />
        </S.MenuBarItem>
      </S.MenuBarGroup>
    </S.MenuBarWrapper>
  );
};

export default RightHandBar;
