import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as S from './style';

const Avatar = React.memo(() => (
  <S.AvatarWrap>
    <StaticImage
      src="../../../static/assets/img/dakv3440.png"
      alt="Avatar"
      placeholder="blurred"
    />
  </S.AvatarWrap>
));

Avatar.displayName = 'Avatar';

export default Avatar;
