const links = [
  { label: 'Github', url: 'https://github.com/eberjoe' },
  { label: 'Twitter', url: 'https://twitter.com/eber246' },
  {
    label: 'Linkedin',
    url: 'https://www.linkedin.com/in/ebermr'
  }
];

export default links;
