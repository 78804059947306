import React from 'react';
import Icons from './Icons';
import links from './content';

import * as S from './style';

const SocialLinks = ({ insist = false }: { insist?: boolean }) => (
  <S.SocialLinksWrap insist={insist}>
    <S.SocialLinksList>
      {links.map((link, i) => {
        const Icon = Icons[link.label];
        return (
          <S.SocialLinksItem key={i}>
            <S.SocialLinksLink
              href={link.url}
              title={link.label}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.IconWrap>
                <Icon />
              </S.IconWrap>
            </S.SocialLinksLink>
          </S.SocialLinksItem>
        );
      })}
    </S.SocialLinksList>
  </S.SocialLinksWrap>
);

export default SocialLinks;
