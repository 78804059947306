import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Avatar from '../Avatar';

import * as S from './style';
import getThemeColor from '../../utils/getThemeColor';

const Profile = () => {
  const {
    site: {
      siteMetadata: { title, author, description }
    }
  } = useStaticQuery(
    graphql`
      query MySiteMetadata {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
      }
    `
  );

  return (
    <S.ProfileWrap>
      <S.ProfileLink
        cover
        direction="right"
        bg={getThemeColor()}
        duration={0.6}
        to="/"
      >
        <Avatar />
        <S.ProfileAuthor>
          {title}
          <S.ProfileEmail>{author}</S.ProfileEmail>
        </S.ProfileAuthor>
      </S.ProfileLink>
      <S.ProfileDescription>{description}</S.ProfileDescription>
    </S.ProfileWrap>
  );
};

export default Profile;
