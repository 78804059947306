const links = [
  {
    label: 'Blog',
    url: '/'
  },
  {
    label: 'About Me',
    url: '/about/'
  },
  {
    label: 'Portfolio',
    url: '/portfolio/'
  }
];

export default links;
