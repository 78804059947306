declare global {
  interface Window {
    __theme: string;
  }
}

const getThemeColor = () => {
  const theme = typeof undefined !== typeof window && window.__theme;

  if (theme === 'day') return '#fff';
  if (theme === 'night') return '#100f';
};

export default getThemeColor;
