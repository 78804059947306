import React from 'react';
import links from './content';

import * as S from './style';
import getThemeColor from '../../utils/getThemeColor';

const MenuLinks = () => (
  <S.MenuLinksWrap>
    <S.MenuLinksList>
      {links.map((link, i) => (
        <S.MenuLinksItem key={i}>
          <S.MenuLinksLink
            cover
            direction="right"
            bg={getThemeColor()}
            duration={0.6}
            to={link.url}
            activeClassName="active"
          >
            {link.label}
          </S.MenuLinksLink>
        </S.MenuLinksItem>
      ))}
    </S.MenuLinksList>
  </S.MenuLinksWrap>
);

export default MenuLinks;
