import styled from 'styled-components';
import media from 'styled-media-query';

export const AvatarWrap = styled.div`
  .gatsby-image-wrapper {
    z-index: 0;
    border-radius: 50%;
    height: 9rem;
    width: 9rem;
    margin: auto;
    ${media.lessThan('large')`
      height: 2.5rem;
      width: 2.5rem;
  `}
  }
`;
