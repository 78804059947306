import { Github } from '@styled-icons/boxicons-logos/Github';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import XitterIcon from '../XitterIcon';
import React from 'react';

const Icons: Record<string, typeof Github | (() => React.JSX.Element)> = {
  Github,
  Twitter: XitterIcon,
  Linkedin
};

export default Icons;
